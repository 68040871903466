<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="序号" type="index" align="right"></el-table-column>
          <el-table-column label="内容" prop="message"></el-table-column>
          <el-table-column label="电话" align="center" width="240">
            <template #default="{ row }">
              <el-link
                v-if="row.telephone"
                type="primary"
                :icon="Phone"
                :underline="false"
                :href="`tel:${row.telephone}`"
              >{{ row.telephone }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="140">
            <template #default="{ row }">
              <el-button
                v-if="row.status === 0"
                type="primary"
                :icon="Check"
                @click="submit(row.id)"
              >已处理</el-button>
              <el-tag v-else type="success">处理完成</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Phone, Check } from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import request from '@/hooks/request';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/feedback/list', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

onMounted(() => {
  getList();
});

function submit(id) {
  request.post(`/admin/feedback/submit/${id}`).then(() => {
    getList();
  });
}
</script>
